import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CartIcon } from '@/assets/svg/cart.svg';
import Link from '@/components/Link';
import { useTypedSelector } from '@/store/store';
import { selectCountProductsInCart } from '@/store/reducers/cart';
import { getProductCartFromCookie } from '@/utils/common';

interface Props {}

function Cart(props: Props) {
  const [quantity, setQuantity] = useState<number>(0);

  const countProductInCart = useTypedSelector((state) =>
    selectCountProductsInCart(state)
  );

  useEffect(() => {
    setQuantity(getProductCartFromCookie().length);
  }, [countProductInCart]);

  return (
    <Component to="/cart" disabled={!quantity}>
      <Text>Cart</Text>
      <Icon>
        <CartIcon />
      </Icon>
      <Badge>{quantity}</Badge>
    </Component>
  );
}

export default React.memo(Cart);

const Component = styled(Link)<{ disabled: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: #fff;
  border-radius: 20px;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #ddd;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #e2e7f1;
    `};

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    width: auto;
    height: 40px;
    padding: 8px 20px;

    margin-right: 17px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const Text = styled.span`
  display: none;

  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #0d0735;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    display: inline-block;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    margin-left: 6px;
    margin-bottom: 4px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -4px;
  right: -5px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  background: linear-gradient(270deg, #0018ff 0%, #0c0342 100%);
  border-radius: 10px;

  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: #fff;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;
