import { css } from 'styled-components';

import { placeholderAnimation } from '@tager/web-components';

export const customPlaceholder = css`
  animation: ${placeholderAnimation} 2s linear;
  animation-iteration-count: infinite;
  background-image: linear-gradient(
    to right,
    rgb(0 0 0 / 5%) 0,
    rgb(0 0 0 / 5%) 15%,
    rgb(0 0 0 / 3%) 30%
  );
  background-color: var(--bg-color-placeholder);
  background-size: 1200px 100%;
`;
