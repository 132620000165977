import React from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import { Nullable } from '@tager/web-core';

import { ReactComponent as ArrowDropDownIcon } from '@/assets/svg/arrow-drop-down.svg';
import { OptionType } from '@/typings/common';
import { ReactComponent as DoneIcon } from '@/assets/svg/done.svg';

interface Props {
  value: Nullable<OptionType>;
  onChange: (newSelectedSort: Nullable<OptionType>) => void;
  options: Array<OptionType>;
}

function SearchSelect({ value, options, onChange }: Props) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem ?? null);
    },
  });

  return (
    <Component>
      <Display type="button" {...getToggleButtonProps()} isOpen={isOpen}>
        <DisplayText>{selectedItem?.label}</DisplayText>
        <DisplayIcon isOpen={isOpen}>
          <ArrowDropDownIcon />
        </DisplayIcon>
      </Display>
      <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
        <DropdownMenuLabel>Search item</DropdownMenuLabel>
        {options.length > 0 ? (
          <OptionList>
            {options.map((option, index) => (
              <OptionItem
                isHighlighted={highlightedIndex === index}
                key={`${option.value}${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: option.value === value?.value,
                })}
              >
                <OptionItemIcon isSelected={option.value === value?.value}>
                  <DoneIcon />
                </OptionItemIcon>
                <OptionItemText>{option.label}</OptionItemText>
              </OptionItem>
            ))}
          </OptionList>
        ) : (
          <NoOptions>No options</NoOptions>
        )}
      </DropdownMenu>
    </Component>
  );
}

export default SearchSelect;

const Component = styled.div`
  position: relative;
`;

const Display = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  height: 40px;

  padding: 8px 40px 9px 17px;

  background: #f2f2f2;
  border-radius: 8px;

  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #0d0735;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #e5e5e5;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: #c9c8c8;

      &:hover {
        background-color: #c9c8c8;
      }
    `};

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const DisplayText = styled.span`
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 80px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const DisplayIcon = styled.span<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  right: 16px;

  transform: translateY(-50%);
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  z-index: 99999;

  min-width: 155px;
  padding: 8px 0 10px 0;

  background: #fff;
  border-radius: 8px;

  visibility: hidden;
  opacity: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `};

  &::after {
    content: '';

    position: absolute;
    top: -7px;
    left: 20px;

    width: 0;
    height: 0;

    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 12px solid #fff;
  }
`;

const DropdownMenuLabel = styled.div`
  padding: 0 14px;
  margin-bottom: 16px;

  font-weight: 400;
  font-size: 13px;
  line-height: 14px;

  color: rgba(13, 7, 53, 0.3);
`;

const OptionList = styled.ul``;

const OptionItemText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #0d0735;

  white-space: nowrap;

  transition: color 0.15s ease-in-out;
`;

const OptionItem = styled.li`
  position: relative;
  cursor: pointer;

  padding: 0 25px;
  margin: 10px 0;

  &:hover {
    ${OptionItemText} {
      color: rgb(8, 10, 128);
    }
  }
`;

const OptionItemIcon = styled.span<{ isSelected: boolean }>`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);

  display: none;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) =>
    isSelected &&
    css`
      display: flex;
    `};
`;

const NoOptions = styled.div``;
