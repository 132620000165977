import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';
import { SvgComponent } from '@tager/web-components';

import { ReactComponent as FilterIcon } from '@/assets/svg/filters.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';
import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg';
import { ReactComponent as PlayIcon } from '@/assets/svg/controls-play.svg';
import { ReactComponent as PauseIcon } from '@/assets/svg/controls-pause.svg';

type FilterButtonActionType = 'close' | 'filter' | 'search' | 'play' | 'pause';

type ButtonActionType = {
  icon: FilterButtonActionType;
  isCircle?: boolean;
  className?: string;
  onClick?: () => void;
};

function ButtonIcon({ icon, onClick, className, isCircle }: ButtonActionType) {
  function getIconComponent(
    icon: FilterButtonActionType | undefined
  ): Nullable<SvgComponent> {
    switch (icon) {
      case 'close':
        return CloseIcon;
      case 'filter':
        return FilterIcon;
      case 'search':
        return SearchIcon;
      case 'play':
        return PlayIcon;
      case 'pause':
        return PauseIcon;

      default:
        return null;
    }
  }

  const Icon = getIconComponent(icon);

  return (
    <ButtonActionBlock
      onClick={onClick}
      className={className}
      isCircle={!!isCircle}
    >
      {Icon ? <IconAction as={Icon} /> : null}
    </ButtonActionBlock>
  );
}

const IconAction = styled.svg`
  vertical-align: sub;
  transition: 0.2s;
`;

const ButtonActionBlock = styled.div<{ isCircle: boolean }>`
  display: inline-block;
  border-radius: ${(props) => (props.isCircle ? '50%' : '8px')};
  background-color: #e2e7f1;
  cursor: pointer;
  transition: 0.2s;
  overflow: hidden;
  &:hover {
    opacity: 0.7;
  }
`;

export default ButtonIcon;
