import React from 'react';
import styled from 'styled-components';

import { Preloader } from '@tager/web-components';

import Header from './components/Header/index';

interface HeaderStyleType extends React.CSSProperties {
  '--header-height': string;
}

interface Props {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
}

function Layout({ children, isPreloaderHidden }: Props) {
  return (
    <Component style={{ '--header-height': '70px' } as HeaderStyleType}>
      <Preloader hidden={isPreloaderHidden} />
      <Header />
      <Main>{children}</Main>
    </Component>
  );
}

const Component = styled.div`
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #f4f6fb;
  position: relative;
`;

export default Layout;
