import React from 'react';
import styled from 'styled-components';

import Logo from './components/Logo';
import Search from './components/Search';
import Cart from './components/Cart';

function Header() {
  return (
    <Component>
      <ComponentContainer>
        <Aside>
          <Logo />
        </Aside>
        <Content>
          <Search />
          <Cart />
        </Content>
      </ComponentContainer>
    </Component>
  );
}

export default Header;

const Component = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  display: flex;
  align-items: center;

  width: 100%;
  height: var(--header-height, 70px);

  color: #fff;
  background-color: #000;

  transition: 0.3s;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const ComponentContainer = styled.div`
  position: relative;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  padding: 0 15px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 264px 1fr;
    column-gap: 20px;

    padding: 0 20px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1280px) {
    grid-template-columns: 21.286% 1fr;
  }

  @media (min-width: 1400px) {
  }
`;

const Aside = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  flex: 1 0 0%;

  margin-left: 15px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    margin-left: 0;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;
