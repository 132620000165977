import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export type ReservedButtonVariant =
  | 'contained'
  | 'contained-primary'
  | 'outlined';

export type ButtonVariant = ReservedButtonVariant | CssSnippet;

export const ButtonText = styled.span`
  position: relative;
  z-index: 2;
`;

const variantCssMap: Record<ReservedButtonVariant, CssSnippet> = {
  contained: css`
    background-image: linear-gradient(to left, #0018ff, #0c0342);
    opacity: 0.95;
    position: relative;
    padding: 10px 15px 11px;
    overflow: hidden;
    letter-spacing: normal;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.3em;
      background-image: linear-gradient(to left, #0010ad, #030019);
      transition: opacity 0.5s ease-out;
      z-index: 1;
      opacity: 0;
      ${media.tabletLarge(
        css`
          transition: none;
        `
      )}
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
    &:hover[disabled] {
      background-image: linear-gradient(to left, #0018ff, #0c0342);
      &:after {
        opacity: 0;
      }
    }
  `,
  'contained-primary': css`
    background-color: ${colors.darkBlue};
    padding: 10px 20px 11px;
    &:hover {
      opacity: 0.7;
    }
    &:hover[disabled] {
      background-color: ${colors.darkBlue};
    }
  `,
  outlined: css`
    background: #e2e7f1;
    padding: 9px 20px 8px;
    color: ${colors.darkBlue};
    &:hover {
      opacity: 0.7;
    }
    &:hover[disabled] {
      background: transparent;
    }
  `,
};

export const StyledButton = styled.button<{
  variant?: ButtonVariant;
  fullWidth?: boolean;
}>`
  position: relative;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  text-decoration: none;
  border-radius: 20px;
  color: #fff;
  transition: 0.3s;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  letter-spacing: 1px;
  ${media.tabletLarge(
    css`
      transition: none;
    `
  )}
  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};
  &:disabled {
    cursor: default;
    opacity: 40%;
  }
`;
