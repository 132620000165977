import { CategoryModel } from '@/typings/model';
import { OptionType } from '@/typings/common';

export function convertCategoryListToOptionList(
  categoryList: Array<CategoryModel>
): Array<OptionType> {
  return categoryList.map((category) => ({
    value: String(category.id),
    label: category.name ?? '',
  }));
}

export function getCategoryAliasFromCategoryListByCategoryId(
  categoryList: Array<CategoryModel>,
  categoryId: string | undefined
): string | undefined {
  const foundCategory = categoryList.find(
    (category) => String(category.id) === categoryId
  );
  return foundCategory ? foundCategory.urlAlias ?? undefined : undefined;
}

export function getCategoryIdFromCategoryListByCategoryAlias(
  categoryList: Array<CategoryModel>,
  categoryAlias: string | undefined
): number | undefined {
  const foundCategory = categoryList.find(
    (category) => category.urlAlias === categoryAlias
  );
  return foundCategory ? foundCategory.id : undefined;
}
