import React from 'react';
import styled from 'styled-components';

import { isStringGuard, Nullable } from '@tager/web-core';
import { PictureProps } from '@tager/web-components';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { Picture, PictureMediaQueryType } from '@/components/Picture';
import { colors } from '@/constants/theme';

interface FilterCellType {
  name: string;
  icon: Nullable<ThumbnailType> | string;
  className?: string;
}

function CategoriesValue({ icon, name, className }: FilterCellType) {
  const iconProps: PictureProps<PictureMediaQueryType> = isStringGuard(icon)
    ? { src: icon }
    : convertThumbnailToPictureProps(icon) ?? { src: '' };

  return (
    <CategoryLinkBlock className={className}>
      {icon && <Icon {...iconProps} />}
      <Title>{name}</Title>
    </CategoryLinkBlock>
  );
}

const Title = styled.span`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${colors.darkBlue};
`;

const Icon = styled(Picture)`
  vertical-align: sub;
  display: inline-block !important;
  margin-right: 12px;
  width: 20px;
  height: 20px;
`;

const CategoryLinkBlock = styled.div`
  display: inline-block;
  position: relative;
  text-transform: uppercase;
`;

export default CategoriesValue;
