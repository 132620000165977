import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import {
  Nullable,
  useDebounce,
  useMedia,
  useUpdateEffect,
} from '@tager/web-core';

import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg';
import { OptionType } from '@/typings/common';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { getCategoryList } from '@/store/reducers/catalog';
import {
  getCategoryAliasFromRouterQuerySlug,
  getParamAsString2,
} from '@/utils/common';
import {
  getLiveSearchList,
  getLiveSearchThunk,
  setLiveSearchSucceeded,
} from '@/store/reducers/search';

import SearchCombobox from './components/SearchCombobox';
import SearchSelect from './components/SearchSelect';
import {
  convertCategoryListToOptionList,
  getCategoryIdFromCategoryListByCategoryAlias,
} from './Search.helpers';

function Search() {
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const isMaxWidth768px = useMedia('(max-width: 768px)');
  const isMaxWidth992px = useMedia('(max-width: 992px)');

  const categoryList = useTypedSelector(getCategoryList);

  const optionList = useMemo(
    () => [
      { value: 'all', label: isMaxWidth768px ? 'All' : 'All items' },
      ...convertCategoryListToOptionList(categoryList),
    ],
    [categoryList, isMaxWidth768px]
  );

  const [selectedCategory, setSelectedCategory] = useState<
    Nullable<OptionType>
  >(() => {
    const categoryAlias = getCategoryAliasFromRouterQuerySlug(
      router.query.slug
    );
    if (categoryAlias) {
      const categoryId = getCategoryIdFromCategoryListByCategoryAlias(
        categoryList,
        categoryAlias
      );
      return (
        optionList.find((option) => option.value === String(categoryId)) ??
        optionList[0]
      );
    }
    return optionList[0];
  });

  useUpdateEffect(() => {
    const categoryAlias = getCategoryAliasFromRouterQuerySlug(
      router.query.slug
    );

    if (categoryAlias) {
      const categoryId = getCategoryIdFromCategoryListByCategoryAlias(
        categoryList,
        categoryAlias
      );

      setSelectedCategory(
        optionList.find((option) => option.value === String(categoryId)) ??
          optionList[0]
      );
      return;
    }

    setSelectedCategory(optionList[0]);
  }, [router.query.slug]);

  const foundCategory = categoryList.find(
    (category) => String(category.id) === selectedCategory?.value
  );

  const categoryAlias = foundCategory?.urlAlias ?? undefined;

  function handleSearchSelectChange(selectedCategory: Nullable<OptionType>) {
    setSelectedCategory(selectedCategory);
  }

  const [searchQuery, setSearchQuery] = useState<string>(
    getParamAsString2(router.query, 'query') ?? ''
  );

  useEffect(() => {
    setSearchQuery(getParamAsString2(router.query, 'query') ?? '');
  }, [router.query]);

  const debouncedSearchQuery = useDebounce<string>(searchQuery, 300);

  function handleInputComboboxChange(value: string) {
    setSearchQuery(value);
  }

  function handleInputClear() {
    setSearchQuery('');

    if (router.query.query) {
      router.replace(
        {
          pathname: categoryAlias ?? '/',
          query: omit(router.query, ['slug', 'query']),
        },
        undefined,
        {
          shallow: false,
        }
      );
    }
  }

  const liveSearchList = useTypedSelector(getLiveSearchList);

  const abortController = useRef<AbortController | null>(null);

  useUpdateEffect(() => {
    if (abortController.current) {
      abortController.current?.abort();
    }

    abortController.current = new AbortController();

    if (debouncedSearchQuery) {
      dispatch(
        getLiveSearchThunk({
          query: debouncedSearchQuery,
          category: foundCategory?.id,
          signal: abortController.current?.signal,
        })
      );
    } else {
      dispatch(setLiveSearchSucceeded([]));
    }

    return () => {
      abortController.current?.abort();
    };
  }, [debouncedSearchQuery]);

  // const prevSearchQuery = useRef<string>(searchQuery);

  function handleSubmitClick() {
    // if (prevSearchQuery.current === searchQuery) {
    //   return;
    // }
    // prevSearchQuery.current = searchQuery;

    const query: Record<string, Array<string> | string> = {
      ...omit(router.query, ['slug', 'page']),
    };

    if (searchQuery) {
      query.query = searchQuery;
    } else {
      delete query.query;
    }

    router.replace(
      { pathname: router.pathname === '/cart' ? '/' : categoryAlias, query },
      undefined,
      {
        shallow: false,
      }
    );
  }

  function handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSubmitClick();
    }
  }

  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState<boolean>(false);

  function handleMobileSubmit() {
    setIsOpenMobileSearch(!isOpenMobileSearch);
  }

  if (isMaxWidth992px) {
    return (
      <MobileComponent>
        <MobileComponentContainer isOpenMobileSearch={isOpenMobileSearch}>
          <SearchSelect
            value={selectedCategory}
            onChange={handleSearchSelectChange}
            options={optionList}
          />
          <SearchCombobox
            placeholder="Search ..."
            items={liveSearchList ?? []}
            inputValue={searchQuery}
            onInputChange={handleInputComboboxChange}
            onInputKeyDown={handleInputKeyDown}
            onInputClear={handleInputClear}
            onItemClick={handleSubmitClick}
          />
        </MobileComponentContainer>
        <MobileSubmitButton onClick={handleMobileSubmit}>
          <SearchIcon />
        </MobileSubmitButton>
      </MobileComponent>
    );
  }

  return (
    <Component>
      <ComponentContainer>
        <SearchSelect
          value={selectedCategory}
          onChange={handleSearchSelectChange}
          options={optionList}
        />
        <SearchCombobox
          placeholder="Search ..."
          items={liveSearchList ?? []}
          inputValue={searchQuery}
          onInputChange={handleInputComboboxChange}
          onInputKeyDown={handleInputKeyDown}
          onInputClear={handleInputClear}
          onItemClick={handleSubmitClick}
        />
      </ComponentContainer>
      <Submit onClick={handleSubmitClick}>
        <SearchIcon />
      </Submit>
    </Component>
  );
}

export default React.memo(Search);

const MobileComponent = styled.div`
  margin-right: 15px;
`;

const MobileComponentContainer = styled.div<{ isOpenMobileSearch: boolean }>`
  position: absolute;
  top: 0;
  right: calc(15px + 40px + 15px);
  bottom: 0;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 40px;

  border-radius: 8px;

  background-color: #fff;

  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  opacity: 0;
  visibility: hidden;

  > * {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0ms cubic-bezier(0, 0, 0.2, 1) 112.5ms;
  }

  ${({ isOpenMobileSearch }) =>
    isOpenMobileSearch &&
    css`
      opacity: 1;
      visibility: visible;

      > * {
        opacity: 1;
        visibility: visible;
      }

      overflow: initial;

      transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      width: calc(100% - 15px - 40px - 15px - 15px);

      @media (min-width: 768px) {
        width: calc(100% - 15px - 97px - 15px - 40px - 15px - 15px);
      }
    `};
`;

const MobileSubmitButton = styled.button`
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: #e2e7f1;
  border-radius: 8px;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #d2d9e9;
  }
`;

const Component = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: 40px;

  margin-right: 15px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    background: #fff;
    border-radius: 8px;
  }

  @media (min-width: 992px) {
    width: 100%;
    max-width: 100%;

    margin-right: 30px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

const ComponentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  background: #fff;
  border-radius: 8px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    position: relative;

    width: 100%;
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const Submit = styled.button`
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: #e2e7f1;
  border-radius: 8px;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #d2d9e9;
  }
`;
