import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LogoIcon } from '@/assets/svg/logo.svg';
import Link from '@/components/Link';

interface Props {}

function Logo(props: Props) {
  return (
    <Component to="/">
      <LogoIcon />
    </Component>
  );
}

export default React.memo(Logo);

const Component = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 97px;
    height: 29px;
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    margin: 0 auto 0 42px;

    svg {
      width: 121px;
      height: 36px;
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;
