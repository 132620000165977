import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useCombobox } from 'downshift';

import { useUpdateEffect } from '@tager/web-core';

import { LiveSearchModel } from '@/typings/model';
import { ReactComponent as CloseIcon } from '@/assets/svg/close_24dp.svg';

interface Props {
  placeholder?: string;
  inputValue?: string;
  items: Array<LiveSearchModel>;
  onInputChange?: (value: string) => void;
  onInputKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onItemClick?: () => void;
  onInputClear?: () => void;
}

function SearchCombobox({
  placeholder,
  inputValue,
  items,
  onInputChange,
  onInputKeyDown,
  onItemClick,
  onInputClear,
}: Props) {
  const [inputItems, setInputItems] = useState<Array<string>>(
    items.map((item) => item.name)
  );

  useUpdateEffect(() => {
    setInputItems(items.map((item) => item.name));
  }, [items]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useCombobox({
    inputValue,
    items: inputItems,
    onInputValueChange: ({ inputValue, type }) => {
      if (onInputChange) {
        onInputChange(inputValue ?? '');
      }

      setInputItems(
        items
          .filter((item) =>
            item.name.toLowerCase().includes((inputValue ?? '').toLowerCase())
          )
          .map((item) => item.name)
      );

      // if (type === useCombobox.stateChangeTypes.ItemClick) {
      //   if (onItemClick) {
      //     setTimeout(() => {
      //       onItemClick();
      //     }, 500);
      //   }
      // }
    },
  });

  return (
    <Component>
      <Display {...getComboboxProps()}>
        <Input
          {...getInputProps({
            onKeyDown: (event) => {
              if (highlightedIndex === -1 && onInputKeyDown) {
                onInputKeyDown(event);
                closeMenu();
              }
            },
          })}
          placeholder={placeholder}
        />
        {inputValue && (
          <Clear onClick={onInputClear}>
            <CloseIcon />
          </Clear>
        )}
      </Display>
      <List
        {...getMenuProps()}
        isOpen={inputValue && isOpen && inputItems.length > 0}
      >
        {inputItems.map((item, index) => (
          <Item
            highlightedIndex={highlightedIndex === index}
            key={`${item}${index}`}
            {...getItemProps({
              item,
              index,
            })}
          >
            {item}
          </Item>
        ))}
      </List>
    </Component>
  );
}

export default SearchCombobox;

const Component = styled.div`
  position: relative;
  flex: 1;
`;

const Display = styled.div`
  height: 40px;

  @media (max-width: 992px) {
    padding-right: 40px;
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 100%;
  height: 100%;

  padding: 0 40px 0 20px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000;

  border: none;
  background-color: transparent;

  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(13, 7, 53, 0.3);
  }
`;

const Clear = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    fill: rgb(13 7 53 / 70%);

    transition: fill 0.15s ease-in-out;
  }

  &:hover {
    svg {
      fill: rgb(13, 7, 53);
    }
  }

  @media (max-width: 992px) {
    right: 50px;
  }
`;

const List = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;

  opacity: 0;
  visibility: hidden;

  padding-bottom: 10px;
  border-radius: 0 0 8px 8px;

  color: #000;
  background-color: #fff;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};

  @media (max-width: 992px) {
    right: 40px;
  }
`;

const Item = styled.li<{ highlightedIndex: boolean }>`
  position: relative;
  cursor: pointer;

  padding: 5px 20px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0d0735;

  ${({ highlightedIndex }) =>
    highlightedIndex &&
    css`
      background-color: rgb(0 0 0 / 7%);
    `};
`;
